import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["poster"];
var __jsx = React.createElement;
import React from 'react';
import { urlFor } from '../../../sanity';
import { ThumbVideo } from './Thumbnails';
export function TutorialVideo(_ref) {
  var poster = _ref.poster,
    props = _objectWithoutProperties(_ref, _excluded);
  var posterUrl = poster && urlFor(poster).width(300).url();
  return __jsx(ThumbVideo, _extends({
    poster: posterUrl
  }, props));
}