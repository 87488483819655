import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { ButtonLink, useHatchBreakpoints } from '@playful/design_system';
import { triggerCustomEvent } from '@playful/telemetry';
import React from 'react';
import portfolio from './assets/01-portfolio.mp4';
import gravity from './assets/02-gravity.mp4';
import links from './assets/03-links-page.png';
import color from './assets/04-color.mp4';
import { CTACarouselDesktop } from './CTACarouselDesktop';
import { CTACarouselMobile } from './CTACarouselMobile';
import { VideoPreview } from './VideoPreview';
var items = [{
  text: 'an unforgettable portfolio',
  asset: portfolio,
  type: 'video'
}, {
  text: 'a business page with gravity',
  asset: gravity,
  type: 'video'
}, {
  text: 'a links page with soul',
  asset: links.src,
  type: 'image'
}, {
  text: 'a colorful gallery',
  asset: color,
  type: 'video'
}];
export function CTACarousel() {
  var _useHatchBreakpoints = useHatchBreakpoints(),
    isLargeScreen = _useHatchBreakpoints.isLargeScreen;
  if (isLargeScreen) {
    return __jsx(CTACarouselDesktop, {
      items: items
    });
  }
  return __jsx(CTACarouselMobile, {
    items: items
  });
}
export function CarouselAsset(props) {
  var text = props.text,
    type = props.type,
    asset = props.asset,
    isActive = props.isActive,
    isPreviouslyActive = props.isPreviouslyActive;
  return __jsx(React.Fragment, null, type === 'video' && __jsx(VideoPreview, {
    height: '100%',
    style: {
      objectFit: 'cover'
    },
    src: asset,
    className: "carousel-asset ".concat(isActive ? 'carousel-asset-active' : isPreviouslyActive ? 'carousel-asset-inactive' : '')
  }), type === 'image' && __jsx("img", {
    src: asset,
    alt: text,
    className: "carousel-asset ".concat(isActive ? 'carousel-asset-active' : isPreviouslyActive ? 'carousel-asset-inactive' : '')
  }));
}
export function CTA(props) {
  return __jsx(ButtonLink, _extends({
    href: '/new-project',
    onClick: triggerCustomEvent('homepage-cta-click', {
      link: 'startyourwebpage'
    }),
    bg: 'yellow.500',
    mt: {
      base: 6,
      md: 12
    },
    px: 4,
    size: 'lg',
    color: 'gray.700'
  }, props), "start your webpage");
}