import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["onSelection", "tutorial", "children"];
var __jsx = React.createElement;
import { Box, ThumbnailCardLabel } from '@playful/design_system';
import React from 'react';
import { ThumbFrame } from './Thumbnails';
export function DefaultCard(_ref) {
  var onSelection = _ref.onSelection,
    tutorial = _ref.tutorial,
    children = _ref.children,
    boxProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(React.Fragment, null, __jsx(ThumbFrame, _extends({
    onClick: function onClick() {
      return onSelection(tutorial);
    }
  }, boxProps), children), __jsx(Box, {
    display: "flex",
    justifyContent: "space-between",
    mt: 2,
    gap: 1,
    alignItems: "flex-start",
    wordBreak: "break-all"
  }, __jsx(ThumbnailCardLabel, {
    color: "white"
  }, tutorial.title)));
}