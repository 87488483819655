import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["isActive"];
var __jsx = React.createElement;
import { AspectRatio, List, ListItem } from '@chakra-ui/react';
import { Box } from '@playful/design_system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CTA, CarouselAsset } from './CTACarousel';
import { HeaderText } from './homePageComponents';
export var CTACarouselDesktop = function CTACarouselDesktop(_ref) {
  var items = _ref.items;
  var _useState = useState(0),
    previouslyActiveIndex = _useState[0],
    setPreviouslyActiveIndex = _useState[1];
  var _useState2 = useState(0),
    activeIndex = _useState2[0],
    setActiveIndex = _useState2[1];
  var carouselWrapperRef = useRef(null);
  var timeoutRef = useRef();
  var handlePointerEventSetIndex = function handlePointerEventSetIndex(index) {
    return function (event) {
      var element = event.target;
      handleSetIndex(index, element);
    };
  };
  var handleSetIndex = useCallback(function (index, element) {
    if (element) {
      var _element$parentElemen;
      var listElScroller = (_element$parentElemen = element.parentElement) === null || _element$parentElemen === void 0 ? void 0 : _element$parentElemen.parentElement;
      if (listElScroller) {
        var scrollToPoint = element.offsetWidth / 2 + element.offsetLeft - listElScroller.offsetWidth / 2;
        listElScroller.scrollTo({
          left: scrollToPoint,
          behavior: 'smooth'
        });
      }
    }
    setPreviouslyActiveIndex(activeIndex);
    setActiveIndex(index);
  }, [activeIndex]);
  var handleAutoAdvance = useCallback(function () {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(function () {
      if (!carouselWrapperRef.current) return;
      var newIndex = activeIndex + 1;
      if (newIndex >= items.length) {
        newIndex = 0;
      }
      var element = carouselWrapperRef.current.children[newIndex];
      handleSetIndex(newIndex, element);
    }, 5000);
  }, [activeIndex, handleSetIndex, items.length]);
  useEffect(function () {
    return function () {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  useEffect(function () {
    handleAutoAdvance();
  }, [activeIndex, handleAutoAdvance]);
  return __jsx(React.Fragment, null, __jsx(Box, {
    id: 'cta-list',
    display: 'flex',
    justifyContent: 'center',
    flexBasis: 1,
    flexGrow: 1,
    flexShrink: 0.5,
    minWidth: {
      base: 385
    }
  }, __jsx(Box, {
    width: '100%',
    maxWidth: '100%',
    overflow: 'visible'
  }, __jsx(HeaderText, {
    mb: 4
  }, "webpages made ", __jsx("br", null), " with hatch"), __jsx(List, {
    spacing: 6,
    ref: carouselWrapperRef
  }, items.map(function (item, index) {
    return __jsx(CarouselListItem, {
      key: item.text,
      isActive: activeIndex === index,
      onPointerUp: handlePointerEventSetIndex(index),
      onPointerOver: handlePointerEventSetIndex(index)
    }, item.text);
  })), __jsx(CTA, null))), __jsx(Box, {
    flexGrow: 1,
    flexBasis: 1,
    flexShrink: 0.5
  }, __jsx(AspectRatio, {
    ratio: 514 / 750,
    maxHeight: '750',
    maxWidth: '514'
  }, __jsx(Box, {
    id: 'image-holder',
    height: '100%',
    width: '100%',
    borderRadius: '32px',
    overflow: 'hidden',
    alignSelf: 'stretch',
    position: 'relative',
    margin: 'auto'
  }, items.map(function (item, index) {
    return __jsx(CarouselAsset, _extends({
      isActive: index === activeIndex,
      isPreviouslyActive: previouslyActiveIndex === index,
      key: item.text
    }, item));
  })))));
};
function CarouselListItem(_ref2) {
  var isActive = _ref2.isActive,
    props = _objectWithoutProperties(_ref2, _excluded);
  return __jsx(ListItem, _extends({
    fontSize: {
      base: 20,
      md: 28,
      xl: 32
    },
    color: isActive ? 'white' : 'blue.200',
    fontWeight: isActive ? '800' : '500',
    transition: 'all 0.2s ease-in-out',
    className: "".concat(isActive ? 'carousel-list-item-active' : ''),
    cursor: 'pointer',
    whiteSpace: 'nowrap'
  }, props));
}