import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { Box, Heading, PlusIcon, Text, Modal, ModalOverlay, ModalBody, ModalContent, ModalFooter, ModalHeader, Button, IconButton, CloseIcon, TrashIcon } from '@playful/design_system';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { useUserProjects } from '../hooks/useProjects';
import { ROUTE_NEW_PROJECT } from '../routes';
import { useUserContext } from '../user/UserContext';
import { FEATURE_INTERNAL_TOOLS } from '@playful/api';
import React, { useEffect, useState } from 'react';
import { createProjectFromDirectory } from './importProject';
import { buildProjectEditRoute } from '../paths';
import { MyProjectsGrid } from './MyProjectsGrid';
import { useRouter } from '../hooks/useRouter';
import { useDisclosure } from '@chakra-ui/react';
export function MyProjects(props) {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasFlag = _useUserContext.hasFlag;
  var _useUserProjects = useUserProjects(user.id, undefined, {
      revalidateOnFocus: false,
      revalidateOnMount: false,
      revalidateIfStale: false
    }),
    projectInfos = _useUserProjects.projectInfos,
    newProjectInfo = _useUserProjects.newProjectInfo,
    deleteProject = _useUserProjects.deleteProject;
  var _useState = useState(),
    dropFiles = _useState[0],
    setDropFiles = _useState[1];
  var _useState2 = useState(),
    dropFileEntries = _useState2[0],
    setDropFileEntries = _useState2[1];
  var _useState3 = useState(new Set()),
    selectedInfos = _useState3[0],
    setSelectedInfos = _useState3[1];
  var _useDisclosure = useDisclosure(),
    isOpen = _useDisclosure.isOpen,
    onOpen = _useDisclosure.onOpen,
    onClose = _useDisclosure.onClose;
  var _useState4 = useState(false),
    isCmdPressed = _useState4[0],
    setIsCmdPressed = _useState4[1];
  useEffect(function () {
    var handleKeyDown = function handleKeyDown(event) {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsCmdPressed(true);
      }
      if (event.key === 'Escape') {
        setIsCmdPressed(false);
        setSelectedInfos(new Set());
      }
    };
    var handleKeyUp = function handleKeyUp(event) {
      if (event.key === 'Meta' || event.key === 'Control') {
        setIsCmdPressed(false);
      }
    };
    var handleMouseLeave = function handleMouseLeave() {
      return setIsCmdPressed(false);
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('mouseleave', handleMouseLeave);
    return function () {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.addEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  var isBulkModeEnabled = isCmdPressed || !!selectedInfos.size;

  // Employees can drag drop exported project directories onto "my projects" to import them.
  useEffect(function () {
    if (dropFileEntries) {
      var fileEntry = dropFileEntries[0];
      if (fileEntry && fileEntry.isDirectory) {
        // Create a project containing all the files in the directory then open it
        // in the Workbench. Special smarts for info.json and project.json files.
        createProjectFromDirectory(fileEntry, newProjectInfo, user).then(function (info) {
          return push(buildProjectEditRoute(info));
        });
      } else {
        // TODO: Make more robust to handle an arbitrary list of files, not just
        // directories.
      }
      setDropFiles(undefined);
      setDropFileEntries(undefined);
    }
  }, [dropFiles, dropFileEntries, newProjectInfo, user, push]);
  var enableProjectImport = hasFlag(FEATURE_INTERNAL_TOOLS);
  return __jsx(Box, _extends({}, props, {
    onDragOver: enableProjectImport ? onDragOver : undefined,
    onDrop: enableProjectImport ? onDrop : undefined
  }), __jsx(Heading, {
    as: "h1",
    size: "lg",
    noOfLines: 1,
    display: "flex",
    gap: 2
  }, "my projects", isBulkModeEnabled && !!selectedInfos.size && __jsx(Box, {
    bg: "gray.50",
    padding: 1,
    border: '1px',
    borderColor: 'gray.100',
    borderRadius: "full",
    display: "flex",
    gap: 3,
    alignItems: "center",
    boxShadow: "sm",
    fontSize: "sm"
  }, __jsx(IconButton, {
    variant: "ghost",
    size: "xs",
    rounded: "full",
    "aria-label": "leave bulk mode",
    onClick: function onClick() {
      return setSelectedInfos(new Set());
    },
    icon: __jsx(CloseIcon, null)
  }), __jsx("span", null, selectedInfos.size, " selected"), __jsx(IconButton, {
    variant: "ghost",
    size: "xs",
    rounded: "full",
    "aria-label": "delete projects",
    onClick: onOpen,
    icon: __jsx(TrashIcon, null)
  }))), projectInfos.length ? __jsx(MyProjectsGrid, {
    onBulkSelection: function onBulkSelection(info) {
      if (selectedInfos.has(info.id)) {
        selectedInfos.delete(info.id);
        setSelectedInfos(function (prev) {
          return new Set(prev);
        });
      } else {
        setSelectedInfos(function (prev) {
          return new Set(prev).add(info.id);
        });
      }
    },
    isBulkModeEnabled: isBulkModeEnabled,
    selectedInfos: selectedInfos
  }) : __jsx(Box, {
    mt: 8,
    height: 180,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid var(--play-colors-gray-100)',
    borderRadius: 'md'
  }, __jsx(Box, {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    justifyContent: 'center',
    alignItems: 'center'
  }, __jsx(Text, {
    color: 'gray.900'
  }, "You don't have any projects yet :("), __jsx(CTAButton, {
    leftIcon: __jsx(PlusIcon, null),
    onClick: function onClick() {
      return push({
        query: {
          newProject: true
        }
      }, ROUTE_NEW_PROJECT, {
        shallow: true
      });
    }
  }, "create one"))), __jsx(Modal, {
    isOpen: isOpen,
    onClose: onClose,
    isCentered: true
  }, __jsx(ModalOverlay, null), __jsx(ModalContent, {
    maxWidth: '460px',
    mx: 4
  }, __jsx(ModalHeader, {
    pt: 6
  }, "Delete these projects forever?"), __jsx(ModalBody, {
    fontWeight: 'normal',
    fontSize: '1rem'
  }, "If you delete these projects they will be gone forever. If any of these projects have been published, any links to them will break. They will be removed from the gallery and your profile."), __jsx(ModalFooter, {
    gap: 4,
    pb: 6
  }, __jsx(Button, {
    variant: 'outline',
    w: '100%',
    onClick: onClose,
    borderColor: 'gray.200'
  }, "cancel"), __jsx(CTAButton, {
    colorScheme: "red",
    w: '100%',
    onClick: function onClick() {
      selectedInfos.forEach(function (id) {
        return deleteProject(id);
      });
      setSelectedInfos(new Set());
      onClose();
    }
  }, "delete them")))));
  function onDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }
  function onDrop(event) {
    // Keep URL drops from navigating away.
    event.preventDefault();
    if (event.dataTransfer.files.length > 0) {
      setDropFiles(event.dataTransfer.files);
      // We must extract the file entries immediately because dataTransfer.items.webkitGetAsEntry()
      // contents are cleared after this event is processed.
      var items = [];
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        var item = event.dataTransfer.items[i];
        var getAsEntry = item.getAsEntry || item.webkitGetAsEntry;

        // IE does not support getAsEntry. But do we really support IE?
        // https://caniuse.com/mdn-api_datatransferitem_webkitgetasentry
        if (!getAsEntry) {
          alert('This ancient browser does not support importing projects via drag/drop.');
          return;
        }
        items.push(getAsEntry.call(item));
      }
      setDropFileEntries(items);
    }
  }
}