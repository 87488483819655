import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { AspectRatio } from '@chakra-ui/react';
import { ThumbnailImage, ThumbnailVideo } from '@playful/design_system';
import React from 'react';
export function ThumbImage(props) {
  return __jsx(ThumbnailImage, _extends({
    borderRadius: "sm"
  }, props));
}
export function ThumbVideo(props) {
  return __jsx(ThumbnailVideo, _extends({
    borderRadius: "sm"
  }, props));
}
export function ThumbFrame(props) {
  return __jsx(AspectRatio, _extends({
    ratio: 4 / 3,
    w: "300px",
    h: "226px",
    cursor: "pointer"
  }, props));
}