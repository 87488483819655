import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["idx"],
  _excluded2 = ["children", "totalCount", "hideControls"],
  _excluded3 = ["direction"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { Box, isSmallScreen } from '@playful/design_system';
import { createGenericContext } from '@playful/utils';
import { useAnimation } from 'framer-motion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
var _createGenericContext = createGenericContext({
    displayName: 'SimpleCarousel'
  }),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  useCarouselCtx = _createGenericContext2[0],
  CarouselProvider = _createGenericContext2[1];
export function useSimpleCarouselItem(_ref) {
  var idx = _ref.idx,
    observer = _ref.observer;
  var _useCarouselCtx = useCarouselCtx(),
    addHandler = _useCarouselCtx.addHandler;
  var elRef = useRef(null);
  var el = elRef.current;
  useEffect(function () {
    return addHandler(idx, function (scrollTo) {
      scrollTo(elRef);
    });
  }, [addHandler, idx, elRef]);
  useEffect(function () {
    if (el) observer === null || observer === void 0 || observer.observe(el);
    return function () {
      if (el) observer === null || observer === void 0 || observer.unobserve(el);
    };
  }, [observer, el]);
  return {
    ref: elRef
  };
}

// all values from 0.01 to 1
var threshold = [].concat(_toConsumableArray(Array.from({
  length: 100
}, function (_, i) {
  return i / 100;
}).filter(Boolean)), [1]);
export function useSimpleCarousel(_ref2) {
  var totalCount = _ref2.totalCount,
    _ref2$hideControls = _ref2.hideControls,
    hideControls = _ref2$hideControls === void 0 ? false : _ref2$hideControls;
  var _useState = useState([]),
    visibleIndexes = _useState[0],
    setVisibleIndexes = _useState[1];
  var _useState2 = useState(),
    observer = _useState2[0],
    setObserver = _useState2[1];
  var isScrolling = useRef(false);
  var bodyRef = useRef(null);
  var handlers = useRef({});
  var controls = useAnimation();
  var scrollStop = useDebouncedCallback(function () {
    return isScrolling.current = false;
  }, 100);
  var _useState3 = useState(false),
    isSmScreen = _useState3[0],
    setSmScreen = _useState3[1];
  useEffect(function () {
    setSmScreen(isSmallScreen());
  }, []);
  useEffect(function () {
    var observer = new IntersectionObserver(function (entries) {
      setVisibleIndexes(function (prev) {
        var updatedItems = new Set(prev);
        var _iterator = _createForOfIteratorHelper(entries),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var entry = _step.value;
            var _idx = Number(entry.target.dataset.idx);

            // sometimes, even if it's fully visible, it will be 0.99 and not 1. so, we leave room for
            // a tiny margin of error.
            if (entry.intersectionRatio >= 0.99 && entry.isIntersecting) {
              updatedItems.add(_idx);
            } else {
              updatedItems.delete(_idx);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        var allIntersections = entries.filter(function (entry) {
          return entry.isIntersecting;
        });

        // if no items are totally visible, but there is at least 1 visible item, we should to find the most visible
        // item and use that as our anchor point.
        if (!updatedItems.size && allIntersections.length) {
          var highestIntersecting = allIntersections.reduce(function (acc, curr) {
            return acc.intersectionRatio > curr.intersectionRatio ? acc : curr;
          });
          var intersectingIdx = Number(highestIntersecting.target.dataset.idx);
          updatedItems.add(intersectingIdx);
        }
        return Array.from(updatedItems);
      });
    }, {
      // the strategy is to use all thresholds, with filtering logic to determine which items are visible.
      // we need this as in some circumstances and screen sizes, there may be scenarios where even one slide
      // is too big to completely fit, but we should still show arrows
      threshold: threshold,
      // checks only for visibility on the x axis (we don't care about y for our slideshow)
      rootMargin: '100% 0% 100% 0%'
    });
    setObserver(observer);
    return function () {
      return observer.disconnect();
    };
  }, []);

  // detect when the user is scrolling
  useEffect(function () {
    var scrollEl = bodyRef.current;
    if (!scrollEl) return;
    var handleScroll = function handleScroll() {
      isScrolling.current = true;
      scrollStop();
    };
    scrollEl.addEventListener('scroll', handleScroll);
    return function () {
      scrollEl.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling, scrollStop]);
  var addHandler = useCallback(function (idx, handler) {
    handlers.current[idx] = handler;
    return function () {
      return delete handlers.current[idx];
    };
  }, []);
  var scrollRefIntoView = function scrollRefIntoView(ref) {
    if (!ref.current || !bodyRef.current) return;
    var elementRect = ref.current.getBoundingClientRect();

    // if user is mid-scroll and tries to navigate, override overflow to try and stop inertia
    if (isScrolling.current) {
      bodyRef.current.style.overflowX = 'hidden';
      bodyRef.current.style.overflowX = 'auto';
    }

    // we use scroll over scrollIntoView because scrollIntoView won't run if the user is in the middle of scrolling,
    // and is inconsistent in what it determines as "in view".
    bodyRef.current.scroll({
      left: elementRect.left + bodyRef.current.scrollLeft - bodyRef.current.clientWidth / 2 + elementRect.width / 2,
      behavior: 'smooth'
    });
  };
  function onNext() {
    var _handlers$current$idx, _handlers$current;
    var idx = Math.min(Math.max.apply(Math, _toConsumableArray(visibleIndexes)) + 1, totalCount - 1);
    (_handlers$current$idx = (_handlers$current = handlers.current)[idx]) === null || _handlers$current$idx === void 0 || _handlers$current$idx.call(_handlers$current, scrollRefIntoView);
  }
  function onPrev() {
    var _handlers$current$idx2, _handlers$current2;
    var idx = Math.max(0, Math.min.apply(Math, _toConsumableArray(visibleIndexes)) - 1);
    (_handlers$current$idx2 = (_handlers$current2 = handlers.current)[idx]) === null || _handlers$current$idx2 === void 0 || _handlers$current$idx2.call(_handlers$current2, scrollRefIntoView);
  }
  var getItemProps = function getItemProps(_ref3) {
    var idx = _ref3.idx,
      props = _objectWithoutProperties(_ref3, _excluded);
    return _objectSpread({
      scrollSnapAlign: 'center',
      'data-idx': idx
    }, props);
  };
  var getNextProps = function getNextProps(props) {
    return _objectSpread({
      onClick: onNext,
      hidden: hideControls || isSmScreen || visibleIndexes.includes(totalCount - 1)
    }, props);
  };
  var getPrevProps = function getPrevProps(props) {
    return _objectSpread({
      onClick: onPrev,
      hidden: hideControls || isSmScreen || !visibleIndexes.length || visibleIndexes.includes(0)
    }, props);
  };
  var getItemListProps = function getItemListProps(props) {
    var _props$style;
    return _objectSpread({
      overflowX: 'auto',
      scrollSnapType: 'x mandatory',
      display: 'grid',
      gridGap: 4,
      listStyleType: 'none',
      alignItems: 'center',
      scrollBehavior: 'smooth',
      sx: _objectSpread({
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        grid: 'auto / auto-flow max-content'
      }, (_props$style = props === null || props === void 0 ? void 0 : props.style) !== null && _props$style !== void 0 ? _props$style : {})
    }, props);
  };
  return {
    controls: controls,
    addHandler: addHandler,
    getNextProps: getNextProps,
    getItemListProps: getItemListProps,
    getPrevProps: getPrevProps,
    onNext: onNext,
    onPrev: onPrev,
    getItemProps: getItemProps,
    observer: observer,
    bodyRef: bodyRef
  };
}
export function SimpleCarousel(_ref4) {
  var children = _ref4.children,
    totalCount = _ref4.totalCount,
    hideControls = _ref4.hideControls,
    props = _objectWithoutProperties(_ref4, _excluded2);
  var ctx = useSimpleCarousel({
    totalCount: totalCount,
    hideControls: hideControls
  });
  return __jsx(CarouselProvider, {
    value: ctx
  }, __jsx(Box, _extends({
    pos: "relative"
  }, props), children));
}
export function SimpleCarouselNextPrev(_ref5) {
  var direction = _ref5.direction,
    props = _objectWithoutProperties(_ref5, _excluded3);
  var _useCarouselCtx2 = useCarouselCtx(),
    getNextProps = _useCarouselCtx2.getNextProps,
    getPrevProps = _useCarouselCtx2.getPrevProps;
  var dirProps = direction === 'prev' ? getPrevProps(props) : getNextProps(props);
  return __jsx(IconButton, _extends({
    "aria-hidden": "true",
    size: "lg",
    icon: direction === 'prev' ? __jsx(ChevronLeftIcon, null) : __jsx(ChevronRightIcon, null),
    pos: "absolute",
    top: 'calc(50% - 24px)',
    left: direction === 'prev' ? 0 : undefined,
    right: direction === 'next' ? 0 : undefined,
    zIndex: 'sticky',
    shadow: 'md',
    _focus: {
      shadow: 'md'
    },
    colorScheme: "white",
    variant: "solid"
  }, dirProps));
}
export function SimpleCarouselBody(props) {
  var _useCarouselCtx3 = useCarouselCtx(),
    getItemListProps = _useCarouselCtx3.getItemListProps,
    bodyRef = _useCarouselCtx3.bodyRef,
    controls = _useCarouselCtx3.controls;
  return __jsx(Box, _extends({
    ref: bodyRef,
    controls: controls
  }, getItemListProps(props)));
}
export function SimpleCarouselItem(props) {
  var _useCarouselCtx4 = useCarouselCtx(),
    getItemProps = _useCarouselCtx4.getItemProps,
    observer = _useCarouselCtx4.observer;
  var _useSimpleCarouselIte = useSimpleCarouselItem({
      idx: props.idx,
      observer: observer
    }),
    ref = _useSimpleCarouselIte.ref;
  return __jsx(Box, _extends({
    ref: ref
  }, getItemProps(props)));
}