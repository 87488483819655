import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["isActive"];
var __jsx = React.createElement;
import { AspectRatio, forwardRef } from '@chakra-ui/react';
import { Box } from '@playful/design_system';
import { useGesture } from '@use-gesture/react';
import React, { useEffect, useRef, useState } from 'react';
import { CTA, CarouselAsset } from './CTACarousel';
import { HeaderText } from './homePageComponents';
export var CTACarouselMobile = function CTACarouselMobile(_ref) {
  var items = _ref.items;
  var _useState = useState(0),
    activeIndex = _useState[0],
    setActiveIndex = _useState[1];
  var carouselWrapperRef = useRef();
  useEffect(function () {
    var carouselWrapper = carouselWrapperRef.current;
    var stop = function stop(event) {
      event.preventDefault();
      event.stopPropagation();
    };
    carouselWrapper === null || carouselWrapper === void 0 || carouselWrapper.addEventListener('touchmove', stop, {
      passive: false
    });
    return function () {
      carouselWrapper === null || carouselWrapper === void 0 || carouselWrapper.removeEventListener('touchmove', stop);
    };
  }, []);
  return __jsx(React.Fragment, null, __jsx(Box, {
    id: 'cta-list',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%'
  }, __jsx(Box, {
    flexGrow: 1,
    flexBasis: 1,
    flexShrink: 0,
    width: '100%',
    maxWidth: 324
  }, __jsx(AspectRatio, {
    ratio: 612 / 892
  }, __jsx(Box, {
    id: 'image-holder',
    borderRadius: '32px',
    overflow: 'hidden',
    boxShadow: '0px 15px 80px 0px rgba(0, 0, 0, 0.20), 0px 5px 10px 0px rgba(0, 0, 0, 0.20), 0px 0px 0px 1px rgba(0, 0, 0, 0.10);',
    position: 'relative',
    width: '100%'
  }, items.map(function (item, index) {
    return __jsx(CarouselAsset, _extends({
      isActive: index === activeIndex,
      key: item.text
    }, item));
  })))), __jsx(HeaderText, {
    my: 6,
    textAlign: "center"
  }, "webpages made with hatch"), __jsx(Box, {
    width: '100vw',
    overflowX: 'hidden',
    ref: carouselWrapperRef
  }, __jsx(ListCarousel, {
    items: items,
    activeIndex: activeIndex,
    setActiveIndex: setActiveIndex
  })), __jsx(CTA, null)));
};
function ListCarousel(props) {
  var trackRef = useRef();
  var activeItemRef = useRef();
  var items = props.items,
    activeIndex = props.activeIndex,
    setActiveIndex = props.setActiveIndex;
  var _useState2 = useState(0),
    xOffset = _useState2[0],
    setXOffset = _useState2[1];
  var timeoutRef = useRef();
  var previousIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
  var nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
  var nextItem = items[nextIndex];
  var previousItem = items[previousIndex];
  var activeItem = items[activeIndex];
  var handleClickNext = function handleClickNext() {
    setActiveIndex(nextIndex);
  };
  var handleClickPrevious = function handleClickPrevious() {
    setActiveIndex(previousIndex);
  };
  var handleAutoAdvance = function handleAutoAdvance() {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(function () {
      handleClickNext();
    }, 5000);
  };
  useEffect(function () {
    handleAutoAdvance();
    return function () {
      clearTimeout(timeoutRef.current);
    };
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  var bind = useGesture({
    onDrag: function onDrag(state) {
      var _state$swipe = _slicedToArray(state.swipe, 1),
        swipeX = _state$swipe[0],
        _state$delta = _slicedToArray(state.delta, 1),
        dx = _state$delta[0],
        memo = state.memo;
      if (!trackRef.current || !activeItemRef.current) return;
      var _ref2 = memo || {
          movedX: 0
        },
        movedX = _ref2.movedX;
      if (swipeX !== 0) {
        if (swipeX === -1) {
          setActiveIndex(nextIndex);
        } else {
          setActiveIndex(previousIndex);
        }
        return;
      }
      var trackRect = trackRef.current.getBoundingClientRect();
      var trackQuarter = trackRect.width / 4;

      // if we've moved more than 25% the width of the screen then we should move to the next item
      if (movedX > trackQuarter) {
        setActiveIndex(previousIndex);
        movedX = 0;
      } else if (movedX < -trackQuarter) {
        setActiveIndex(nextIndex);
        movedX = 0;
      } else {
        setXOffset(function (x) {
          return x + dx;
        });
      }
      return {
        movedX: movedX + dx
      };
    },
    onDragStart: function onDragStart() {
      clearTimeout(timeoutRef.current);
    },
    onDragEnd: function onDragEnd() {
      handleAutoAdvance();
    }
  }, {
    drag: {
      axis: 'x'
    }
  });
  useEffect(function () {
    if (!trackRef.current || !activeItemRef.current) return;
    var trackRect = trackRef.current.getBoundingClientRect();
    var activeItemRect = activeItemRef.current.getBoundingClientRect();
    var trackCenter = trackRect.left + trackRect.width / 2;
    var activeItemCenter = activeItemRect.left + activeItemRect.width / 2;
    var diff = trackCenter - activeItemCenter;
    setXOffset(diff + 8);
  }, [activeIndex]);
  return __jsx(Box, _extends({
    display: 'flex',
    gap: 4,
    ref: trackRef,
    transform: "translateX(".concat(xOffset, "px)")
  }, bind()), __jsx(ListCarouselBoxItem, {
    key: previousItem.text,
    onClick: handleClickPrevious
  }, previousItem.text), __jsx(ListCarouselBoxItem, {
    key: activeItem.text,
    isActive: true,
    ref: activeItemRef
  }, activeItem.text), __jsx(ListCarouselBoxItem, {
    key: nextItem.text,
    onClick: handleClickNext
  }, nextItem.text));
}
var ListCarouselBoxItem = forwardRef(function (_ref3, ref) {
  var isActive = _ref3.isActive,
    props = _objectWithoutProperties(_ref3, _excluded);
  return __jsx(Box, _extends({
    fontSize: {
      base: 20,
      md: 28,
      xl: 32
    },
    color: isActive ? 'white' : 'blue.200',
    fontWeight: isActive ? '800' : '500',
    transition: 'all 0.2s ease-in-out',
    className: "".concat(isActive ? 'carousel-list-item-active' : ''),
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    display: 'flex',
    ref: ref
  }, props));
});