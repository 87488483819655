import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "background", "backgroundColor", "id"],
  _excluded2 = ["children"];
var __jsx = React.createElement;
import { Box, Text } from '@playful/design_system';
import React from 'react';
export function PageSection(_ref) {
  var children = _ref.children,
    background = _ref.background,
    backgroundColor = _ref.backgroundColor,
    id = _ref.id,
    boxProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Box, {
    display: 'flex',
    justifyContent: 'center',
    background: background,
    backgroundColor: backgroundColor,
    id: id
  }, __jsx(Box, _extends({
    maxWidth: {
      base: 'calc(min(1280px, 100%))'
    },
    width: {
      base: 'calc(min(1280px, 100%))'
    },
    px: {
      base: 4,
      md: 10,
      xl: 4,
      '2xl': 0
    },
    id: "".concat(id, "-inner")
  }, boxProps), children));
}
export function HeaderText(_ref2) {
  var children = _ref2.children,
    TextProps = _objectWithoutProperties(_ref2, _excluded2);
  return __jsx(Text, _extends({
    variant: 'sectionHeader',
    fontSize: {
      base: 32,
      md: 44,
      xl: 60
    },
    fontWeight: 'extrabold',
    color: 'white'
  }, TextProps), children);
}