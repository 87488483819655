import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
export function VideoPreview(props) {
  return __jsx("video", _extends({
    loop: true,
    autoPlay: true,
    muted: true,
    playsInline: true,
    disablePictureInPicture: true
  }, props));
}