import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["tutorial"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { ModalHeader, useDisclosure } from '@chakra-ui/react';
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, isSmallScreen, useIsSmallScreen } from '@playful/design_system';
import { SimpleCarousel, SimpleCarouselBody, SimpleCarouselItem, SimpleCarouselNextPrev } from '../../SimpleCarousel';
import { useLocalStorageState } from '../../../hooks/handyHooks';
import { customEvent } from '@playful/telemetry';
import React, { useCallback, useState } from 'react';
import { CalloutCard } from './CalloutCard';
import { DefaultCard } from './DefaultCard';
import { TutorialProject } from './TutorialProject';
import { TutorialVideo } from './TutorialVideo';
export function TutorialsCarousel(_ref) {
  var tutorials = _ref.tutorials,
    infos = _ref.infos;
  var _useDisclosure = useDisclosure(),
    onOpen = _useDisclosure.onOpen,
    onClose = _useDisclosure.onClose,
    isOpen = _useDisclosure.isOpen;
  var _useState = useState(),
    videoUrl = _useState[0],
    setVideoUrl = _useState[1];
  var openVideoModal = useCallback(function (videoUrl) {
    setVideoUrl(videoUrl);
    onOpen();
  }, [onOpen, setVideoUrl]);
  var _useLocalStorageState = useLocalStorageState([], 'tutorialHiddenCalloutKeys'),
    _useLocalStorageState2 = _slicedToArray(_useLocalStorageState, 2),
    hiddenCalloutKeys = _useLocalStorageState2[0],
    setHiddenCalloutKeys = _useLocalStorageState2[1];
  var filteredTutorials = tutorials.filter(function (tutorial) {
    return !hiddenCalloutKeys.includes(tutorial._key);
  });
  var handleSelection = useCallback(function (tutorial) {
    if (tutorial._type === 'tutorialVideo') openVideoModal(tutorial.video.mediaUrl);
    customEvent('tutorial-view', _objectSpread({
      title: tutorial.title
    }, tutorial._type === 'tutorialProject' && {
      projectId: tutorial.project.id
    }));
  }, [openVideoModal]);
  var isSmScreen = useIsSmallScreen(true);

  // quick n dirty inline render prop-like function to conditionally render our various card types to avoid
  // prop drilling (having to pass in all potentially required props to a single component, something that
  // weighs down our other Card/Thumbnail components).
  var TutorialCard = useCallback(function (_ref2) {
    var tutorial = _ref2.tutorial,
      boxProps = _objectWithoutProperties(_ref2, _excluded);
    if (tutorial.callout && !isSmScreen) return __jsx(CalloutCard, _extends({
      tutorial: tutorial,
      onSelection: handleSelection,
      onCollapse: function onCollapse(_ref3) {
        var _key = _ref3._key;
        setHiddenCalloutKeys(function (prevKeys) {
          return [].concat(_toConsumableArray(prevKeys), [_key]);
        });
        customEvent('tutorial-close-callout', {
          title: tutorial.title
        });
      }
    }, boxProps));
    return __jsx(DefaultCard, _extends({
      tutorial: tutorial,
      onSelection: handleSelection
    }, boxProps));
  }, [handleSelection, setHiddenCalloutKeys, isSmScreen]);
  return __jsx(React.Fragment, null, __jsx(SimpleCarousel, {
    w: "100%",
    totalCount: filteredTutorials.length
  }, __jsx(SimpleCarouselNextPrev, {
    "aria-label": "previous item",
    direction: "prev",
    left: {
      base: 4,
      md: 10
    }
  }), __jsx(SimpleCarouselBody, null, filteredTutorials.map(function (tutorial, i) {
    return __jsx(SimpleCarouselItem, {
      key: i,
      idx: i
      // we want the carousel to scroll beyond the padding (full bleed), so we add the padding conditionally
      // to the start and end slides
      ,
      pl: i === 0 ? {
        base: 6,
        md: 12
      } : undefined,
      pr: i === filteredTutorials.length - 1 ? {
        base: 6,
        md: 12
      } : undefined
    }, __jsx(TutorialCard, {
      tutorial: tutorial
    }, tutorial._type === 'tutorialProject' ? __jsx(TutorialProject, {
      info: infos[tutorial.project.id],
      previewUrl: tutorial.project.previewVideoUrl
    }) : __jsx(TutorialVideo, {
      src: tutorial.video.mediaUrl,
      poster: tutorial.video.poster
    })));
  })), __jsx(SimpleCarouselNextPrev, {
    "aria-label": "next item",
    direction: "next",
    right: {
      base: 4,
      md: 10
    }
  })), videoUrl && __jsx(Modal, {
    size: isSmallScreen() ? 'almost-full-mobile' : 'almost-full',
    isOpen: isOpen,
    onClose: onClose,
    "aria-labelledby": "form-dialog-title",
    scrollBehavior: "inside"
  }, __jsx(ModalOverlay, null), __jsx(ModalContent, {
    bg: "gray.900",
    color: "white"
  }, __jsx(ModalHeader, {
    display: "flex",
    justifyContent: "flex-end",
    px: 6,
    pt: 6,
    pb: 3.5
  }, __jsx(ModalCloseButton, {
    top: 0,
    right: 0,
    zIndex: "modal",
    position: "relative",
    bg: "gray.700",
    color: "white",
    _focus: {
      bg: 'gray.500'
    },
    _active: {
      bg: 'gray.500'
    },
    _hover: {
      bg: 'gray.500'
    }
  })), __jsx(ModalBody, {
    px: 10,
    pb: 10,
    pt: 0,
    display: "flex"
  }, __jsx(Box, {
    as: "video",
    flexGrow: 1,
    controls: true,
    maxH: "100%",
    w: "100%",
    bg: "black",
    autoPlay: true,
    src: videoUrl
  })))));
}