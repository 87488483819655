import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["tutorial", "onSelection", "onCollapse", "children"];
var __jsx = React.createElement;
import { Box, Button, CloseIcon, Heading, IconButton, PlayActionIcon, Text, isSmallScreen } from '@playful/design_system';
import { useUserContext } from '../../../user/UserContext';
import React from 'react';
import { ThumbFrame } from './Thumbnails';
export function CalloutCard(_ref) {
  var tutorial = _ref.tutorial,
    onSelection = _ref.onSelection,
    onCollapse = _ref.onCollapse,
    children = _ref.children,
    boxProps = _objectWithoutProperties(_ref, _excluded);
  var _useUserContext = useUserContext(),
    isLoggedInUser = _useUserContext.isLoggedInUser;
  return __jsx(Box, _extends({
    display: "flex",
    bg: "purple.700",
    p: 4,
    borderRadius: "md",
    gap: 6,
    pos: "relative"
  }, boxProps), isLoggedInUser && !isSmallScreen() && __jsx(IconButton, {
    variant: "ghost",
    "aria-label": "close",
    icon: __jsx(CloseIcon, null),
    minW: 0,
    h: "24px",
    color: "white",
    pos: "absolute",
    top: 3,
    right: 3,
    onClick: function onClick() {
      return onCollapse(tutorial);
    }
  }), __jsx(ThumbFrame, {
    onClick: function onClick() {
      return onSelection(tutorial);
    }
  }, children), __jsx(Box, {
    w: "304px",
    display: "flex",
    flexDir: "column",
    justifyContent: "center",
    gap: 3
  }, __jsx(Heading, {
    as: "h3",
    size: "md"
  }, tutorial.title), __jsx(Text, null, tutorial.content), __jsx(Box, null, __jsx(Button, {
    colorScheme: "yellow",
    color: "gray.700",
    size: "md",
    leftIcon: __jsx(PlayActionIcon, null),
    onClick: function onClick() {
      return onSelection(tutorial);
    }
  }, "watch now"))));
}