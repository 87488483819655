import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["children", "color", "Icon"];
var __jsx = React.createElement;
import { AspectRatio } from '@chakra-ui/react';
import { Box, DesignToolsIcon, GlassIcon, Link, LinkIcon, MagicPenIcon, Text } from '@playful/design_system';
import { formatEventString, triggerCustomEvent } from '@playful/telemetry';
import React from 'react';
import dragAndDrop from './assets/01-drag-drop.mp4';
import codeless from './assets/02-codeless.mp4';
import aiCode from './assets/03-ai-code.mp4';
import bringTogether from './assets/04-bring-together.mp4';
import { HeaderText } from './homePageComponents';
import { VideoPreview } from './VideoPreview';
export function VideoTimeline() {
  return __jsx(React.Fragment, null, __jsx(Box, {
    display: 'flex',
    justifyContent: 'left',
    overflow: "hidden"
  }, __jsx(Box, {
    id: 'video-list',
    color: 'white',
    ml: {
      base: 5,
      md: 8
    },
    pt: {
      base: 20
    },
    display: 'flex',
    flexDirection: 'column'
  }, __jsx(VideoItem, {
    title: __jsx(React.Fragment, null, "drag + drop ", __jsx("br", null), "design"),
    Icon: DesignToolsIcon,
    color: '#FFD401',
    video: dragAndDrop,
    linkText: 'start designing',
    subtitle: 'Start from a template or a blank canvas. Import your own graphics or pick from our library. Stack, tilt, and drag anywhere without the limits of a grid.'
  }), __jsx(VideoItem, {
    title: __jsx(React.Fragment, null, "interactivity", __jsx("br", null), "without code"),
    Icon: GlassIcon,
    color: '#A368F4',
    video: codeless,
    linkText: 'get moving',
    subtitle: 'Add one-click interactive effects to create animation, motion, and responsive physics without technical expertise.'
  }), __jsx(VideoItem, {
    title: __jsx(React.Fragment, null, "customizable", __jsx("br", null), "code"),
    Icon: MagicPenIcon,
    color: 'pink.500',
    video: aiCode,
    linkText: 'build something new',
    subtitle: 'Use our AI coder to describe the interactions and animations you want. Hatch will write and run the code in your project. Ask for changes or edit the code yourself.'
  }), __jsx(VideoItem, {
    title: 'publish & share with one easy link',
    Icon: LinkIcon,
    color: '#B4E053'
  }))), __jsx(Box, {
    mt: {
      base: 8,
      md: 14
    },
    borderRadius: '16px',
    overflow: 'hidden'
  }, __jsx(AspectRatio, {
    ratio: 1200 / 776
  }, __jsx(VideoPreview, {
    src: bringTogether
  }))));
}
export function VideoItem(props) {
  var video = props.video,
    subtitle = props.subtitle,
    boxProps = props.boxProps,
    color = props.color,
    linkText = props.linkText,
    title = props.title,
    Icon = props.Icon;
  return __jsx(Box, {
    display: 'flex',
    alignItems: 'stretch'
  }, __jsx(Box, {
    mx: 0,
    ml: {
      base: '10px',
      lg: '7px'
    },
    width: {
      base: '3px',
      lg: '3px'
    },
    minWidth: {
      base: '3px',
      lg: '3px'
    },
    maxWidth: {
      base: '3px',
      lg: '3px'
    },
    mr: {
      base: 9,
      md: 12
    },
    height: 'calc(100% + var(--play-space-14))',
    backgroundClip: 'content-box',
    background: color
  }, __jsx(TimelineIcon, {
    Icon: Icon,
    color: color
  })), __jsx(Box, _extends({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 10,
    flexDirection: {
      base: 'column',
      lg: 'row'
    },
    alignSelf: video ? undefined : 'baseline',
    pos: 'relative',
    mb: video ? {
      base: '80px',
      md: '112px'
    } : 0
  }, boxProps), __jsx(Box, {
    pos: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    flexBasis: '50%',
    flexGrow: 1,
    alignSelf: 'baseline',
    transform: {
      base: 'translateY(7px)',
      md: 'none',
      xl: 'translateY(-3px)'
    }
  }, __jsx(HeaderText, {
    pr: {
      base: '48px',
      md: 0
    },
    minWidth: {
      base: 0,
      xl: '584'
    }
  }, title), subtitle && __jsx(React.Fragment, null, __jsx(Text, {
    fontSize: {
      base: 16,
      md: 20,
      lg: 28
    },
    fontWeight: '400',
    lineHeight: '150%',
    pr: {
      base: '48px',
      md: 0
    }
  }, subtitle), __jsx(Box, {
    mt: 0
  }, __jsx(Link, {
    color: color,
    href: '/new-project',
    fontSize: {
      base: 16,
      md: 20,
      lg: 24
    },
    onClick: triggerCustomEvent('homepage-cta-click', {
      link: formatEventString(linkText !== null && linkText !== void 0 ? linkText : '')
    })
  }, linkText)))), video && __jsx(Box, {
    alignSelf: 'stretch',
    width: {
      base: 'calc(100vw - 64px)',
      md: '100%'
    },
    transform: {
      base: 'translate(-56px)',
      md: 'none'
    },
    flexBasis: '50%'
  }, __jsx(Box, {
    id: 'video-holder',
    borderRadius: '16px',
    overflow: 'hidden'
  }, __jsx(AspectRatio, {
    ratio: 560 / 540,
    maxWidth: 560,
    maxHeight: 540
  }, __jsx(VideoPreview, {
    src: video
  }))))));
}
function TimelineIcon(_ref) {
  var children = _ref.children,
    color = _ref.color,
    Icon = _ref.Icon,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Box, _extends({
    height: '64px',
    width: '44px',
    bg: 'gray.950',
    display: 'flex',
    py: 4,
    transform: 'translateX(-13px)'
  }, props), __jsx(Icon, {
    h: 8,
    width: 8,
    color: color
  }));
}