var __jsx = React.createElement;
import { RouteLink } from '@playful/design_system';
import { useProjectThumb } from '../../../explorer/ProjectThumbnailCard';
import { buildUserRoute } from '../../../paths';
import React from 'react';
import { ThumbImage, ThumbVideo } from './Thumbnails';
import { useRouter } from '../../../hooks/useRouter';
export function TutorialProject(_ref) {
  var previewUrl = _ref.previewUrl,
    info = _ref.info,
    onClick = _ref.onClick;
  var _useProjectThumb = useProjectThumb({
      projectId: info === null || info === void 0 ? void 0 : info.id,
      videoUrl: previewUrl
    }),
    mediaUrl = _useProjectThumb.mediaUrl,
    poster = _useProjectThumb.poster,
    type = _useProjectThumb.type;
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath;
  if (!info) return null;
  return __jsx(RouteLink, {
    asPath: info && buildUserRoute(info.ownerName, info.slug),
    href: {
      query: {
        from: asPath,
        userName: info.ownerName,
        slug: info.slug,
        playerModal: true
      }
    },
    shallow: true,
    onClick: onClick
  }, type === 'video' ? __jsx(ThumbVideo, {
    src: mediaUrl,
    poster: poster,
    title: info.title
  }) : __jsx(ThumbImage, {
    src: mediaUrl
  }));
}